var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"infoCollect"},[_c('div',{staticClass:"titles"},[_c('span',{staticClass:"text"},[_vm._v("采集信息")]),_vm._t("btn")],2),_c('div',_vm._l((_vm.data),function(item1,second){return _c('el-descriptions',{key:second,attrs:{"column":1,"direction":"vertical"}},[_c('el-descriptions-item',{attrs:{"label":'● '+item1.itemName,"label-style":{width:'120px',color:'#444444',fontWeight: 'bold',borderBottom:'8px'}}},[(item1.inputType == 1||item1.inputType == 5)?[_vm._v(" "+_vm._s(item1.note)+" ")]:_vm._e(),(item1.inputType == 2 || item1.inputType == 3)?_c('div',_vm._l((item1.items),function(item2){return _c('div',{key:'b' + item2.items,staticStyle:{"position":"relative"}},[(item2.isPitchUp)?[(item2.type == 1)?_c('i',{staticClass:"el-icon-success icon-img",staticStyle:{"color":"#47AB40"}}):_c('i',{staticClass:"el-icon-warning icon-img",staticStyle:{"color":"#F56C6C"}}),_c('span',[_vm._v(_vm._s(item2.content))]),(item2.note)?_c('div',{staticClass:"describ",staticStyle:{"min-width":"180px"}},[_vm._v(" "+_vm._s(item2.note)+" ")]):_vm._e(),(item2.url)?_c('div',{staticClass:"imgContain"},_vm._l((item2.url.split(
											','
										)),function(url,index6){return _c('el-image',{key:index6,staticClass:"imgstyle",attrs:{"src":url,"preview-src-list":item2.url.split(','),"z-index":index6}})}),1):_vm._e()]:_vm._e(),_vm._l((item2.junior),function(item3){return [(item2.isPitchUp && item3.isPitchUp)?_c('div',{key:item3.juniorId,staticStyle:{"margin-top":"8px"}},[_c('el-descriptions',{attrs:{"direction":"vertical"}},[_c('el-descriptions-item',{attrs:{"label-style":{width:'120px',color:'#444444',fontWeight: 'bold'},"label":'● '+item3.juniorName}},[(
													item3.juniorInputType == 1 || item3.juniorInputType == 5
												)?[_vm._v(_vm._s(item3.note)+" ")]:_vm._e(),(
													item3.juniorInputType ==
														2 ||
													item3.juniorInputType == 3
												)?_c('div',_vm._l((item3.juniors),function(item4){return _c('div',{key:'b' +
														item4.juniorsId},[(item4.isPitchUp)?[(item4.juniorsType == 1)?_c('i',{staticClass:"el-icon-success icon-img",staticStyle:{"color":"#47AB40"}}):_c('i',{staticClass:"el-icon-warning icon-img",staticStyle:{"color":"#F56C6C"}}),_vm._v(" "+_vm._s(item4.juniorsContent)+" "),(item4.note)?_c('div',{staticClass:"describ",staticStyle:{"min-width":"180px"}},[_vm._v(" "+_vm._s(item4.note)+" ")]):_vm._e(),(item4.url)?_c('div',{staticClass:"imgContain"},_vm._l((item4.url.split(
																	','
																)),function(url,index5){return _c('el-image',{key:index5,staticClass:"imgstyle",attrs:{"src":url,"preview-src-list":item4.url.split(','),"z-index":index5}})}),1):_vm._e()]:_vm._e()],2)}),0):_vm._e(),(
													item3.juniorInputType == 4
												)?_c('div',[(item3.url)?_c('div',{staticClass:"imgContain"},_vm._l((item3.url.split(
															','
														)),function(url,index4){return _c('el-image',{key:index4,staticClass:"imgstyle",attrs:{"src":url,"preview-src-list":item3.url.split(',')}})}),1):_vm._e()]):_vm._e()],2)],1)],1):_vm._e()]})],2)}),0):_vm._e(),(item1.inputType == 4)?_c('div',{staticClass:"right"},[(item1.url)?_c('div',{staticClass:"imgContain"},_vm._l((item1.url.split(',')),function(url,index3){return _c('el-image',{key:index3,staticClass:"imgstyle",attrs:{"src":url,"preview-src-list":item1.url.split(',')}})}),1):_vm._e()]):_vm._e()],2)],1)}),1)])
}
var staticRenderFns = []

export { render, staticRenderFns }